.app-footer{
    background-color: rgb(79, 79, 79);
    color: white;
}

.app-footer .logo-container {
    flex: 1;
}

.app-footer .nav-container {
    flex: 1;
}

.app-footer .nav-container ul {
    list-style: none;
}

.app-footer .nav-container a {
    color: white;
    text-decoration: none;
}

.app-footer .nav-container a:hover {
    text-decoration: underline;
}
.listing-container .filter-row {
    display: flex;
    gap: 0.5rem;
    padding: 1rem 0;
    justify-content: flex-end;
}

.listing-container .chip {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
    background-color: white;
}
.app-header {
    background-color: blue;
    color: white;
    height: 70px;
}

.app-header .header-container {
    padding: 1rem 2rem;
}

.app-header .nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.app-header .nav-container a {
    padding: 0.5rem;
    color: white;
    text-decoration: none;
}

.app-header .nav-container a.active {
    border: 1px solid white
}

.app-header .nav-container a:hover {
    text-decoration: underline;
}
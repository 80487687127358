Header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.page-wrapper {
    margin-top: 70px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-container {
    padding: 2rem;
}

Footer {
    padding: 1rem 2rem;
}